import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Columns from "../components/columns"
import sal from 'sal.js'
import Slider from "react-slick"
import Hero from "../../static/images/process.png"
import HeroBg from "../../static/images/techbg.png"
import Graph from "../../static/images/graph2.png"
import P1 from "../../static/research/1.jpg"
import P2 from "../../static/research/2.jpg"
import P3 from "../../static/research/3.jpg"
import P4 from "../../static/research/4.jpg"
import P5 from "../../static/research/5.jpg"
import P6 from "../../static/research/6.jpg"
import P7 from "../../static/research/7.jpg"
import P8 from "../../static/research/8.jpg"
import P9 from "../../static/research/9.jpg"
import P10 from "../../static/research/10.jpg"
import P11 from "../../static/research/11.jpg"
import P12 from "../../static/research/12.jpg"
import Sep from "../../static/images/sep.png"

const ProcessPage = () => {

    useEffect(() => {
    // Update the document title using the browser API
        sal({
            threshold: 1
        });
    });

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1800,
    fade:true,
    pauseOnHover: false
  };

  return (
  <Layout>
    <SEO title="Stimulate | Technology page" />

    <section className = ' hero is-fullheight has-bg has-marginbottom-4' style={{backgroundPosition: 'center right', backgroundImage: 'url('+HeroBg+')'}}>
      <div className='hero-body'>
        <div className='container'>
        <Columns classes = 'is-vcentered is-multiline'>
          <div className = 'column is-6'>
              <img src={Hero} style={{filter: 'grayscale(1)'}}></img>
          </div>
          <div className = 'column is-6'>
          <h2 className = 'is-size-0 has-marginbottom-3'> Crafted for comfort</h2>
            <p className = 'is-size-5' style={{lineHeight: '1.5em'}}>Our technology has been tried and tested and has fared extremely well in numerous lab and market tests, be it supporting heel attachment, withstanding temperature and pressure during manufacturing process, the overall insole strength and comfort factor from a consumer point of view as never seen (felt) before.</p>
          </div>
        </Columns>
        </div>
      </div>
    </section>

    {/* <section className = ' hero is-fullheight has-bg' style={{backgroundPosition: 'bottom left', backgroundImage: 'url(./techbg.png)'}}>
      <div className='hero-body'>
        <div className='container'>
        </div>
      </div>
    </section> */}
    
    {/* <Section classes=" is-medium">
          <h2 className = 'is-size-2 is-uppercase has-text-centered has-marginbottom-2'>Our Technology</h2>
        <Columns classes=' is-variable is-8'>
            <div className='column is-half'>
                <p className='is-size-5'>Our technology has been tried and tested and has fared extremely well in numerous lab and market tests, be it supporting heel attachment, withstanding temperature and pressure during manufacturing process, the overall insole strength and comfort factor from the consumer point of view as never seen, felt before.</p>
            </div>
            <div className='column is-half'>
                <p className='is-size-5'>This insole system can increase the comfortability in your existing high heel constructions by 35%, without having to alter moulds of heels, platform and shoe last.<br /><br />Stability is a major problem in high heels, This gives more stability and better support thus reduces chances of wobbling.</p>
            </div>
            <img alt='' src='./sep.png' style={{position: 'absolute', bottom: '-80px', zIndex: '-1'}}></img>
        </Columns>
    </Section> */}

    {/* <Section>
        <Columns classes=' is-variable is-8 is-vcentered'>
            <div className='column is-auto'>
            <h2 className = 'is-size-2 is-uppercase has-marginbottom-3'>Key Tech Features</h2>
                <ul className='is-size-5'>
                    <li>High performance plastic</li>
                    <li>6mm nasa grade dual cushioning</li>
                    <li>Better pressure distribution, more comfort</li>
                    <li>More stable, less wobbling</li>
                    <li>Lightweight, reduced energy expenditure</li>
                </ul>
            </div>
            <div className='column is-three-fifths is-relative'>
                <img alt='' src='./superlight-tech-lg.png' style={{width: '100%'}}></img>
            </div>
        </Columns>
    </Section> */}

    <Section classes=' '>
    <h2 className = 'is-size-2 has-text-centered has-marginbottom-3'>Inception</h2>
        <Columns classes=' is-variable is-8 is-multiline is-vcentered'>
            {/* <div className='column is-half has-marginbottom-2'>
                <h2 className = 'is-size-3 has-marginbottom-2'>Research</h2>
                <p className='is-size-5'>We conducted global research on every brand that claimed to offer comfortable heels. After the wear trials, we found that most of these claims were marketing gimmicks and the difference was marginal in the very few who offered comfort. We also did global patent landscaping, shortlisted certain inventions and reverse engineered them to see their effectiveness.<br /><br />We then compared it with the idea we had for innovation. There was a major difference between the two. That's when Stimulate was born. We worked on the research till the time the company’s founder gained confidence in this product being the best.</p>
            </div>
            <div className='column is-half has-marginbottom-2'>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src='./research/7.jpg'></img>
            </div> */}
            <div className='column is-6 has-marginbottom-2'>
                <p className='is-size-5'>We researched about uneven pressure distribution and ground reaction forces acting on a high heel structure. We also studied about postural and biomechanical effects of high heels, gait parameters, variation in cadence and steps during walking.<br />After understanding the problem in great detail we then got down to creating a product to achieve comfort like never before. We have experimented with around 50 concepts.<br /><br /><strong className='has-text-primary'>The main work went into simplifying things and solving multiple problems with a single solution that works with all kinds of high heels.</strong></p>
            </div>
            <div className='column is-6 has-marginbottom-2'>
                <Slider {...settings} className='process'>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P1}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P2}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P3}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P4}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P5}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P6}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P7}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P8}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P9}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P10}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P11}></img>
                </div>
                <div>
                <img alt='' className='has-shadow-1' style={{width: '100% !important'}} width='100%'  src={P12}></img>
                </div>
                </Slider>
            </div>
                <img alt='' src={Sep} style={{position: 'absolute', bottom: '-80px', zIndex: '-1'}}></img>
        </Columns>
    </Section>


    <Section>
        <Columns classes=' is-variable is-8 is-multiline is-vcentered'>
            <div className='column is-half'>
                <img alt='' style={{mixBlendMode: 'multiply'}} src={Graph} width='100%'></img>
            </div>
            <div className='column is-half'>
            <p className='is-size-5'>We tried the footwear on a sample size of 200 women. Giving them two footwear for trial: Steve Madden and ours (after hiding the branding). <br /><br /><strong>This is what we found.</strong></p><br />
                <p className='is-size-5'>95% of the respondents had rated our footwear 4 or 5 and the average rating for Steve Madden was 2 or 3.<br /><br /><strong>The response was overwhelming.</strong></p>
            </div>
        </Columns>
    </Section>

    {/* <Section classes=' has-background-primary'>
        <Columns classes=' is-variable is-8 is-multiline is-vcentered'>
            <div className='column is-6 has-marginbottom-2 is-offset-3'>
                <p className='is-size-5 is-bold has-text-white'>Our technology has been tried and tested and has fared extremely well in numerous lab and market tests, be it supporting heel attachment, withstanding temperature and pressure during manufacturing process, the overall insole strength and comfort factor from a consumer point of view as never seen (felt) before.</p>
            </div>
        </Columns>
    </Section> */}

  </Layout>
)
}

export default ProcessPage
